module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","zh-CN","ja"],"defaultLanguage":"en","redirect":false,"siteUrl":"https://www.howyoung.dev","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/posts/:segment+","getLanguageFromPath":true},{"matchPath":"/:lang?/about-me","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Howyoung's Blog","short_name":"Howyoung's Blog","description":"A tech enthusiast's blog.","start_url":"/","background_color":"#ffffff","display":"standalone","theme_color_in_head":false,"icon":"/home/runner/work/howyoungzhou.github.io/howyoungzhou.github.io/node_modules/gatsby-theme-blog-ocean/static/icons/icon.svg","icon_options":{"purpose":"any maskable"},"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1721cd7287ee522333cfe1896e8f07d3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-blog-ocean/gatsby-browser.tsx'),
      options: {"plugins":[],"title":"Howyoung's Blog","description":"A tech enthusiast's blog.","siteUrl":"https://www.howyoung.dev","languages":["en","zh-CN","ja"],"localesSource":"locales","i18nOptions":{"redirect":false},"giscusOptions":{"repo":"HowyoungZhou/howyoungzhou.github.io","repoId":"R_kgDOG0Da5Q","category":"Announcements","categoryId":"DIC_kwDOG0Da5c4CYzmw","mapping":"pathname","strict":"1"},"postsSource":"posts","postsFolderLayout":"/:lang/:segment+","postsPathTemplate":"/:lang?/posts/:segment+","defaultLanguage":"en","excerptLength":200,"rssPath":"/rss.xml","maxImageWidth":1920,"manifest":{}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
