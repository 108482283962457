exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-blog-ocean-src-pages-index-tsx": () => import("./../../../node_modules/gatsby-theme-blog-ocean/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ocean-src-pages-index-tsx" */),
  "component---node-modules-gatsby-theme-blog-ocean-src-pages-posts-tsx": () => import("./../../../node_modules/gatsby-theme-blog-ocean/src/pages/posts.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ocean-src-pages-posts-tsx" */),
  "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-en-about-me-mdx": () => import("./../../../node_modules/gatsby-theme-blog-ocean/src/templates/post-query.js?__contentFilePath=/home/runner/work/howyoungzhou.github.io/howyoungzhou.github.io/posts/en/about-me.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-en-about-me-mdx" */),
  "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-en-hello-mdx": () => import("./../../../node_modules/gatsby-theme-blog-ocean/src/templates/post-query.js?__contentFilePath=/home/runner/work/howyoungzhou.github.io/howyoungzhou.github.io/posts/en/hello.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-en-hello-mdx" */),
  "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-en-introducing-gatsby-theme-blog-ocean-md": () => import("./../../../node_modules/gatsby-theme-blog-ocean/src/templates/post-query.js?__contentFilePath=/home/runner/work/howyoungzhou.github.io/howyoungzhou.github.io/posts/en/introducing-gatsby-theme-blog-ocean.md" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-en-introducing-gatsby-theme-blog-ocean-md" */),
  "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-ja-about-me-mdx": () => import("./../../../node_modules/gatsby-theme-blog-ocean/src/templates/post-query.js?__contentFilePath=/home/runner/work/howyoungzhou.github.io/howyoungzhou.github.io/posts/ja/about-me.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-ja-about-me-mdx" */),
  "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-zh-cn-about-me-mdx": () => import("./../../../node_modules/gatsby-theme-blog-ocean/src/templates/post-query.js?__contentFilePath=/home/runner/work/howyoungzhou.github.io/howyoungzhou.github.io/posts/zh-CN/about-me.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ocean-src-templates-post-query-js-content-file-path-posts-zh-cn-about-me-mdx" */)
}

